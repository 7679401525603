import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Button = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.color};
  transition: .5s;
  &:after {
    position: absolute;
    right: 0;
    padding: 0 1rem;
    content: '>';
  }
  &:hover {
    color: ${props => props.backgroundColor};
    background-color: ${props => props.color};
    border: 1px solid ${props => props.color};
  }
`
export const ButtonLink = ({ children, color="#000", backgroundColor="#fff", onClick }) => {
  return (
    <Button
      color={color}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >{children}</Button>
  )
}
