import React, { useState, useRef } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import 'reset-css';
import { Adsense } from "../components/Adsense";
import { ButtonLink } from "../components/ButtonLink";
import mainImage from "./../images/main.jpg"

interface Props {
  children: React.ReactNode;
}

const lightColor = "#fff";
const darkColor = "#1b1f22";
const borderColor = "#ccc";
const breakpoint = 960;

const Layout = styled.div`
  width: 100%;
  height: 100%;
  font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  box-sizing: border-box;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 64px;
  color: ${lightColor};
  background-color: ${darkColor};
`;

const Footer = styled.footer`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${lightColor};
  background-color: ${darkColor};
  text-align: center;
`;

const Copy = styled.small`
  font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  letter-spacing: .065em;
`;

const Link = styled.a`
  color: ${lightColor};
  font-size: 12px;
  text-decoration: underline dotted #999;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px - 64px);
  padding: 1rem 1rem;
  box-sizing: border-box;
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    flex-direction: column;
    height: auto;
  }
`;

const BtnWrapper = styled.div`
  width: 100%;
  //height: calc(100vh - 64px - 64px);
  display: flex;
  flex-direction: column;
  justify-content: between-space;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
`;

const AdsenseWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
`;

const Title = styled.h1`
  width: 100%;
  padding: 1rem 1rem;
  color: ${lightColor};
  background-color: ${darkColor};
  font-size: 1.4rem;
  letter-spacing: .13em;
  box-sizing: border-box;
`;

const Box = styled.textarea`
  width: 100%;
  //height: calc((100vh - 64px - 64px) / 2);
  font-size: 22px;
  font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  transition: height 100ms linear 0s, min-height 100ms linear 0s;
  color: rgb(51, 51, 51);
  border: 1px solid ${borderColor};
  background-color: transparent;
  border-radius: 0.2rem;
  line-height: 1.5;
  letter-spacing: .13em;
  padding: 1rem;
  box-sizing: border-box;
  outline: none;
  ::placeholder {
    opacity: .4;
  }
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    height: 30vh;
  }
`;

const Btn = styled.button`
  position: relative;
  padding: 1rem;
  color: #006494;
  border: 1px solid ${borderColor};
  background-color: transparent;
  border-radius: 1rem;
  margin: 1rem 0;
  font-size: 1.4rem;
  letter-spacing: .13em;
  font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  transition: .2s;
  outline: none;
  box-shadow: 2px 2px 2px rgba(0, 100, 148, .15);
  &:hover {
    color: ${lightColor};
    background-color: ${borderColor};
    box-shadow: 2px 2px 2px ${borderColor};
  }
`;

// POST メソッドの実装の例
async function postData(url = '', data = {}) {
  // 既定のオプションには * が付いています
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // 本文のデータ型は "Content-Type" ヘッダーと一致する必要があります
  })
  return response.json(); // レスポンスの JSON を解析
}

const IndexPage: React.VFC<Props> = (props: Props) => {
  const url = `https://katakana.aventureworld.com`;
  const title = `文章を意識高い系ビジネス用語に変換・翻訳`;
  const description = `文章を意識高い系ビジネス用語に変換・翻訳するWebサイトです。インプットテキストをインテリジェンスにし、ビジネスパワーアップをコミットメントします。`;
  const requestRef = useRef<HTMLTextAreaElement>(null);
  const responseRef = useRef<HTMLTextAreaElement>(null);
  const [request, setRequest] = useState("");
  const [response, setResponse] = useState("");
  const handleClick = () => {
    const r = requestRef.current?.value;
    if (r) {
      postData('https://8suyh7vv28.execute-api.us-east-1.amazonaws.com/v1/convertToKatakana', {
        text: r
      })
      .then(data => {
        setResponse(data.text);
      });
    } else {
      setResponse("");
    }
  }
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <html lang={`ja`} />
        <template>{title}</template>
        <meta name="description" content={description} />
        <meta name="image" content={`${url}${mainImage}`} />
        <meta name="robots" content="index" />
        <meta property="og:site_name" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content={"website"} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${url}${mainImage}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={"@creator_haduki"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${url}${mainImage}`} />
        <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Wrapper breakpoint={breakpoint}>
        <Box
          ref={requestRef}
          defaultValue={request}
          placeholder={"議題の計画はかなりきついです。予定変更を希望します。この費用対効果の証拠はありますか？約束はできる限り早くしてください。今回は外注に賛成します。私はこのまま直帰します。"}
          rows={5}
          breakpoint={breakpoint}
        />
        <BtnWrapper>
          <AdsenseWrapper>
            <Adsense path={"https://katakana.aventureworld.com"} />
          </AdsenseWrapper>
          <ButtonLink color={darkColor} backgroundColor={lightColor} onClick={handleClick}>変換</ButtonLink>
          <AdsenseWrapper>
            <Adsense path={"https://katakana.aventureworld.com"} />
          </AdsenseWrapper>
        </BtnWrapper>
        <Box
          ref={responseRef}
          defaultValue={response}
          value={response}
          placeholder={"アジェンダのスキームはかなりタイトです。リスケをホープします。このコストパフォーマンスのエビデンスはありますか？コミットメントはエーエスピーしてください。今回はアウトソーシングにアグリーします。私はこのままエヌアールします。"}
          rows={5}
          breakpoint={breakpoint}
        />
      </Wrapper>
      <Footer>
        <Copy>
          <Link href="https://aventureworld.com" target="_blank" rel="noopener noreferrer">&copy; 2020 A VENTURE WORLD</Link>
        </Copy>
      </Footer>
    </Layout>
  );
}

export default IndexPage
